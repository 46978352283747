import { Cookies } from 'react-cookie';
import { DocumentNode } from 'graphql/language';
import { ENVS, IContentfulSettings } from '@belong/types';
import { logger } from '@belong/logging/logger';
import { getConfig } from '@belong/configs/next/config';
import { isObject } from '@belong/type-utils';
import { getContentfulSettings } from '../helpers/getContentfulSettings';

const API_ROOT = 'https://graphql.contentful.com/content/v1/spaces';

export const isGQLErrorResponse = (response: IGQLResponse): boolean => {
  return (response.statusCode ?? 999) >= 400 || 'errors' in response;
};

const debugGQLError = (query: string, variables: any, error: any): void => {
  if (getConfig().publicRuntimeConfig.env.isTest) {
    return;
  }

  logger.error('Query:', query);
  logger.error('Variables:', variables);
  logger.error('Error:', error);
};

export interface IContentService {
  getCMSContent: (query: string, config: IContentfulSettings, vars?: any) => Record<string, any>;
}

export interface IGQLResponse<T = any> {
  data?: T;
  statusCode: number;
  errors?: Record<string, any>;
}

function isGQLTag(source: DocumentNode | string): source is DocumentNode {
  return isObject(source);
}

/**
 *
 * @param query properly formed gql query string
 * @param vars
 * @returns Contentful data result from your GQL query. Results from this should not need
 * the existing Factories.
 */
export const getContentByGQL = async <T>(query: DocumentNode | string, vars?: any): Promise<IGQLResponse<T>> => {
  const queryString: string = (isGQLTag(query) ? query.loc?.source.body : query) || '';
  const { environment, space, accessToken } = getContentfulSettings(
    new Cookies(),
    getConfig().publicRuntimeConfig.contentful
  );

  const BEARER_TOKEN = `Bearer ${accessToken}`;
  const contentfulEndpoint = `${API_ROOT}/${space}/environments/${environment}`;
  const { publicRuntimeConfig } = getConfig();
  const variables = { ...vars, isPreview: publicRuntimeConfig.env.stage === ENVS.PREVIEW };
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({ query: queryString, variables }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: BEARER_TOKEN
    }
  };

  try {
    const result = await fetch(contentfulEndpoint, requestOptions);
    const { data }: { data: T & { errors: Record<string, any> } } = await result.json();

    if (data.errors) {
      debugGQLError(queryString, variables, data);
      // Allows us to one day have a more pleasant experience when content is broken
      return {
        statusCode: result.status,
        ...data
      };
    }

    return {
      statusCode: result.status,
      data
    };
  } catch (err) {
    debugGQLError(queryString, variables, err.message);
    return {
      statusCode: 500
    };
  }
};
