import { Entry } from 'contentful';
import type { GetServerSidePropsContext } from 'next';

// Contentful types

export interface IAdobeTargetActivityContent {
  identifier: string;
  contentType: 'adobeTargetActivity';
  mBoxName: string;
  activityId: string;
  experiences: IAdobeTargetExperienceContent[];
}

export interface IAdobeTargetExperienceContent {
  identifier: string;
  contentType: 'adobeTargetExperience';
  experienceName: string;
  experienceContent: Entry<any>[];
}

// Adobe Target types

export interface IMbox {
  index: number;
  name: string;
  options: [
    {
      content: {
        activityName: string;
        experienceName: string;
      };
      type: 'json';
      responseTokens: Record<string, string>;
      sourceType: 'target';
    }
  ];
}

export interface IAdobeTargetActivity {
  client: string;
  edgeHost: string;
  execute: {
    mboxes: IMbox[];
  };
  executionTime: number;
  id: { tntId: string };
  requestId: string;
  status: number;
  supplementalDataId?: string;
  telemetryServerToken: string;
}

export interface IRequestAdobeTargetActivity {
  ctx: GetServerSidePropsContext;
  mboxLocation: string;
  sessionId: string;
  supplementalDataId: string;
  isAuthenticatedCustomer?: boolean;
  forceControlExperience?: boolean;
}

export interface IQAMode {
  token: string;
  previewIndexes: { activityIndex: number; experienceIndex: number }[];
  listedActivitiesOnly?: boolean;
  evaluateAsTrueAudienceIds?: string[];
  evaluateAsFalseAudienceIds?: string[];
}
export interface IFindAdobeTargetActivity {
  ctx: GetServerSidePropsContext;
  sessionId: string; // this should be the correlationId which will be used to track the user through the Adobe Target system
  supplementalDataId: string;
  unparsedPageEntry?: Entry<any>;
  isAuthenticatedCustomer?: boolean;
  forceControlExperience?: boolean;
}

export interface IGetExperienceSections {
  adobeTargetActivity: IAdobeTargetActivityContent;
  adobeTargetActivityResponse: IAdobeTargetActivityResponse;
  forceControlExperience?: boolean;
}

export interface IUpdateSectionsWithAdobeTargetActivity {
  adobeTargetActivityResponse: IAdobeTargetActivityResponse;
  unparsedPageEntry?: Entry<any>;
  forceControlExperience?: boolean;
}

export type IAdobeTargetActivityError = {
  errorMessage: string;
  errorDetail: string;
  executionTime: number;
};

export const isIAdobeTargetActivityError = (
  response?: IAdobeTargetActivityResponse
): response is IAdobeTargetActivityError => {
  return (response as IAdobeTargetActivityError).errorDetail !== undefined;
};

export type IAdobeTargetActivityResponse = IAdobeTargetActivity | IAdobeTargetActivityError;
